import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

const hgkStyle = {
  height: '80px',
  width: 'auto',
  display: 'block',
  marginBottom: '10px',
};

function RivalitasFooter() {
  const classes = useStyles();


  return (
    <Footer
          theme="dark"
          content={
            <div>
              <div className={classes.left}>
              </div>
              <div className={classes.right}>
                Copyright © {moment().year()} <a href="https://www.rivalitas.hr">Rivalitas</a>
              </div>
            </div>
          }
        >
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <h5>O nama</h5>
              <p>
              Upisani smo u Registar posrednika u prometu nekretninama pri Hrvatskoj gospodarskoj komori, naši agenti imaju položen stručni ispit i upisani su u imenik agenata posredovanja u prometu nekretnina, što Vam olakšava prodaju i kupnju nekretnina i štedi Vaše vrijeme i novac.
              </p>
              <img src="/assets/img/hgkWhite.png" alt="Prodaj nekretninu" style={hgkStyle} />

              <Link to="/ONama">Detaljnije</Link>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <h5>Kontaktirajte nas</h5>
              <div className={classes.socialFeed}>
                <div>
                  <i className="fas fa-map-marker-alt"></i>
                  <p><strong>Adresa:</strong> Grahorova 11, 10 000 Zagreb</p>
                </div>
                <div>
                  <i className="fas fa-phone" />
                  <p><strong>Tel:</strong> +385 1 3701 093, +385 91 4640 500</p>
                </div>
                <div>
                  <i className="fas fa-envelope" />
                  <p>
                  <strong>Email:</strong> <a href="mailto:info@rivalitas.hr">info@rivalitas.hr</a>
                  </p>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <h5>Korisno</h5>
              <div className={classes.galleryFeed}>
                <div>
                  <Link to="/UvjetiKoristenja">Uvjeti korištenja</Link>
                </div>
                <div>
                  <a href="/assets/pdf/rivalitas-opci-uvjeti-poslovanja.pdf" target="_blank">Opći uvjeti poslovanja</a>
                </div>
                <div>
                  <Link to="/Prodavatelji">Za prodavatelje</Link>
                </div>
                <div>
                  <Link to="/Kupci">Za kupce</Link>
                </div>
                <div>
                  <Link to="/Kontakt">Kontakt</Link>
                </div>
                <div>
                  <strong>Pratite nas</strong>
                  <Button
                    color="white"
                    justIcon
                    simple
                    href="https://www.facebook.com/profile.php?id=100090570810208"
                    _target="blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </Footer>
  );
}

export default RivalitasFooter;

import React from 'react';
import { Helmet } from 'react-helmet';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useContactStyles = makeStyles(contactStyle);

const hgkStyle = {
  height: '120px',
  width: 'auto',
  display: 'block',
  marginBottom: '10px',
};

function AboutUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const contactClasses = useContactStyles();

  return (
    <div>
      <Helmet>
        <title>Rivalitas Nekretnine - O nama</title>
        <meta name="description" content="Rivalitas Nekretnine - agencija za posredovanje u prometu nekretninama. Specijalizirani smo za prodaju i najam nekretnina u području grada Zagreba." />
        <meta name="keywords" content="Rivalitas Nekretnine, o nama" />
      </Helmet>

      <Parallax image="/assets/img/aboutUs.jpg" filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>O nama</h1>
              <h4>
                <i>Mi brinemo za sve, Vi se samo trebate odlučiti</i>
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={contactClasses.aboutUs}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(contactClasses.mrAuto, contactClasses.mlAuto)}
              >
                <h3 className={classNames(contactClasses.title, contactClasses.textCenter)}>
                  Poštovani klijenti
                </h3>
                <p>
                  Sa radom smo počeli 2006. godine.
                </p>
                <p>
                  Rad s ljudima predstavlja nam zadovoljstvo, a cilj nam je profesionalno i odgovorno obavljanje poslova posredovanja u prometu nekretninama, što Vam može potvrditi i cijeli niz naših zadovoljnih klijenata.
                </p>
                <p>
                  Okupili smo tim profesionalaca koji će Vam pomoći da u najkraćem roku prodate ili pronađete nekretninu koja Vam odgovara uz stručnu pomoć i pravnu sigurnost prilikom finaliziranja posla vezanog za nekretninu.
                </p>
                <p>
                  Upisani smo u Registar posrednika u prometu nekretninama pri <a href="https://www.hgk.hr/" target="_blank" rel="noopener noreferrer">Hrvatskoj gospodarskoj komori</a>, naši agenti imaju položen stručni ispit i upisani su u imenik agenata posredovanja u prometu nekretnina, što Vam olakšava prodaju i kupnju nekretnina i štedi Vaše vrijeme i novac.
                </p>
                <p>
                  Naše poslovanje smo u potpunosti prilagodili odredbama <a href="https://narodne-novine.nn.hr/clanci/sluzbeni/2007_10_107_3128.html" target="_blank" rel="noopener noreferrer">Zakona o posredovanju u prometu nekretnina.</a>
                </p>
                <p>
                  Dokumentacija svih nekretnina iz naše ponude detaljno je provjerena s pravne strane i kupcu (najmoprimcu) garantiramo da kupuje (unajmljuje) nekretninu bez ikakvih mogućih sporova ili zapreka za prijenos vlasništva.
                </p>
                <p>
                  Prodavateljima osiguravamo profesionalno oglašavanje i prezentaciju nekretnine, pronalaženje kupca u kratkom roku kao i svu stručnu dokumentaciju potrebnu za zaključenje prodaje.
                </p>
                <p>
                  Kupcima jamčimo profesionalan i stručan pristup pri odabiru nekretnine na tržištu i svu potrebnu pravnu pomoć prilikom kupnje.
                </p>
                <p>
                  Nekretnine za koje Rivalitas posreduje moraju imati urednu vlasničku dokumentaciju.
                </p>
                <p>
                  Primjedbe na rad agencije Rivalitas d.o.o. možete slati na: <br />
                  Tel: +385 1 3701 093<br />
                  Fax: +385 1 3772 833<br />
                  <strong>Email:</strong> <a href="mailto:info@rivalitas.hr">info@rivalitas.hr</a>
                </p>
                <img src="/assets/img/hgk.png" alt="Prodaj nekretninu" style={hgkStyle} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
